import React, { useState } from "react"
import HeroComponent from "../components/ImageHero.js";
import PageWrapper from "../components/PageWrapper";

import imgVideo from "../assets/image/jpeg/about-d.jpg";
import Trend from '../components/trends.js';
import PopupForm from "../components/PopupForm/PopupForm"


const BlogRightSidebar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <PageWrapper footerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Dermamina </span> <span style="color: #00aec7;">Blog</span>'
    secondText="Latest From Our Blog"
    videoAlt="Blog Non Surgical Treatments"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

 
          
              
          <Trend />
    
      </PageWrapper>
    </>
  );
};
export default BlogRightSidebar;
